import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"

export default function ProcurementMangement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO title="Procurement Management" />
      <SolutionLayout
        data={{
          slug: "proqure",
          hero: {
            image: require("./../assets/Proqure.png"),
            titleSecond: "ProQure",
            subtitle:
              "Streamlined Procurement, Supplier and Contract Management",
            content:
              "ProQure automates the entire procurement process — starting with the initial requisitioning and approvals to purchasing and order tracking — with drag and drop simplicity. It ensures that all purchase requests are tracked in a single system with complete tracing of purchasing request and approval history.",
          },
          section: {
            title: "How can ProQure help you?",
            subtitle: (
              <>
                Streamlining the procurement process can yield remarkable
                results and dramatically impact the bottom line -- especially{" "}
                <br className="hidden md:block" />
                when it comes to such common CPO (Chief Procurement Officer)
                challenges as selecting and onboarding new suppliers, updating
                annual <br className="hidden md:block" />
                vendor qualifications, standardizing contracts, simplifying
                requisition, and speeding up the entire purchase-to-procure
                cycle
              </>
            ),
          },
          imageSection1: {
            image: require("./../assets/proqure1.png"),
            title: "Easily onboard vendors",
            content:
              "Invite vendors to register to bid on contracts or simply register them on proQure. Review their applications and pre- approve the qualified vendors.",
          },
          imageSection2: {
            image: require("./../assets/proqure2.png"),
            title: "Create requisitions and manage procurements",
            content:
              "Generate and approve requisitions from the various departments.Send out requests to vendors, prequalify and manage quotes easily using proQure.",
          },
          information: [],
        }}
      />
    </Layout>
  )
}
